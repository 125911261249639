<template>
  <v-dialog v-model="activeDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new professional background</v-card-title>
      <v-card-text>

        <v-switch
          v-model="view.active"
          :label="`Active: ${view.active}`"
        ></v-switch>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    activeDialog: Boolean,
    myId: { type: Number },
    name: { type: String },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      meetingList: [],
      view: {
        active: false,
      },
    };
  },
  validations: {
    view: {
      active: {
        required,
      },
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.myMeet
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.view.meeting_typesid = response.data.meeting_typesid;
          this.view.active = response.data.active;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeActive", payload);
    },
    submit() {
      let active = this.view.active;

      let data = {
        active,
      };
      console.log("data:", data);
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitActive", payload);
    },
  },
};
</script>
