<template>
  <v-dialog v-model="newDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new consulting service</v-card-title>
      <v-card-text>
        <v-select :items="meetingList" item-text="name" item-value="id" v-model="view.meeting_typesid"
          label="meeting type"></v-select>
        <v-textarea outlined rows="3" label="description" v-model="view.description" maxlength="250" counter></v-textarea>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit" :disabled="$v.view.$invalid">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      meetingList: [],
      view: {
        meeting_typesid: null,
        description: null,
      },
    };
  },
  validations: {
    view: {
      meeting_typesid: {
        required,
      },
      description: {
        required,
      },
    },
  },
  created() {
    this.specialty();
  },
  methods: {
    specialty() {
      this.loading = true;
      let self = this;
      Restful.general.meetypes
        .list()
        .then((response) => {
          console.log(response.data);
          this.meetingList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let meeting_typesid = this.view.meeting_typesid;
      let description = this.view.description;
      let data = {
        meeting_typesid, description
      };
      let payload = { state: false, data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
