<template>
  <v-dialog v-model="viewDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View {{ name }}</v-card-title>
      <v-card-text>
        <div class="mylist">
        <ul>
            <li>
              <div class="myleft">pricing</div>
              <div class="myright">{{ view.pricing }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">description</div>
              <div class="myright">{{ view.description }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">discount</div>
              <div class="myright">{{ view.discount }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">discount_expires</div>
              <div class="myright">{{ view.discount_expires | myDate }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">active</div>
              <div class="myright">{{ view.active }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">active</div>
              <div class="myright">{{ view.active }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">created</div>
              <div class="myright">{{ view.created | myDating }}</div>
              <div class="clearall"></div>
            </li>
          <li>
            <div class="myleft">updated</div>
            <div class="myright">{{ view.updated | myDating }}</div>
            <div class="clearall"></div>
          </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    viewDialog: Boolean,
    myId: { type: Number, default: 0 },
    name: { type: String, default: null },
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      view: {
        pricing: null,
        description: null,
        discount: null,
        discount_expires: null,
        active: null,
        created: null,
        updated: null
      },
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.myMeet
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.view.active = response.data.active;
          this.view.created = response.data.created;
          this.view.description = response.data.description;
          this.view.discount = response.data.discount;
          this.view.discount_expires = response.data.discount_expires;
          this.view.pricing = response.data.pricing;
          this.view.updated = response.data.updated;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeView", payload);
    },
  },
};
</script>
