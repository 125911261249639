<template>
  <v-dialog v-model="editDialog" persistent max-width="500">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>update {{name}}</v-card-title>
      <v-card-text>
        <v-select
            :items="meetingList"
            item-text="name"
            item-value="id"
            v-model="view.meeting_typesid"
            label="meeting type"
        ></v-select>
        <v-text-field label="price" type="number" v-model="view.pricing"></v-text-field>
<!--        <v-text-field label="discount" type="number" max="100" counter v-model="view.discount"></v-text-field>-->

<!--        <v-menu-->
<!--            v-model="menu2"-->
<!--            :close-on-content-click="false"-->
<!--            :nudge-right="40"-->
<!--            transition="scale-transition"-->
<!--            offset-y-->
<!--            min-width="auto"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-text-field-->
<!--                v-model="view.discount_expires"-->
<!--                label="discount expiry date"-->
<!--                prepend-icon="mdi-calendar"-->
<!--                readonly-->
<!--                v-bind="attrs"-->
<!--                v-on="on"-->
<!--            ></v-text-field>-->
<!--          </template>-->
<!--          <v-date-picker-->
<!--              v-model="view.discount_expires"-->
<!--              @input="menu2 = false"-->
<!--          ></v-date-picker>-->
<!--        </v-menu>-->

        <v-textarea outlined rows="3" label="description" v-model="view.description"  maxlength="250" counter></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import moment from "moment";
export default {
  mixins: [validationMixin],
  props: {
    editDialog: Boolean,
    myId: { type: Number },
    name: { type: String },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      meetingList: [],
      menu2: null,
      view: {
        meeting_typesid: null,
        pricing: null,
        discount: 0,
        // discount_expires: null,
        // description: null,
      },
    };
  },
  validations: {
    view: {
      meeting_typesid: {
        required,
      },
    },
  },
  created() {
    this.specialty();
    this.find();
  },
  methods: {
    specialty() {
      this.loading = true;
      let self = this;
      Restful.general.meetypes
        .list()
        .then((response) => {
          console.log(response.data);
          this.meetingList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.myMeet
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.view.meeting_typesid = response.data.meeting_typesid;
          this.view.pricing = response.data.pricing;
          // this.view.discount = response.data.discount;
          // this.view.discount_expires = response.data.discount_expires.length >  moment(response.data.discount_expires).format("yyyy-MM-DD") ? moment(response.data.discount_expires).format("yyyy-MM-DD") : moment().format("yyyy-MM-DD");
          this.view.description = response.data.description;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let meeting_typesid = this.view.meeting_typesid;
      let description = this.view.description;
      let pricing = this.view.pricing;

      let data = {
        meeting_typesid,description,
        pricing
      };
      console.log("data:", data);
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
